import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSessions(ctx, id) {
      const courseg = id.value;

      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getalllecturelessons/${courseg}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCourse(ctx, { id }) {
      //console.log("single course  Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getcoursedetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLesson(ctx, { id }) {
      //console.log("single lesson  Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getlessondetail/${id}`)
          .then(response => {
            // console.log(response)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateCourseInfo(ctx, { id }) {
      //console.log("update course  is launching with id : "+id);
      return;
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getcoursedetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },


    addCourse(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/createlesson', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    reOrderLessons(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/updatelectureLesson', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteLesson(ctx, { id }) {
      console.log(id)
      return new Promise((resolve, reject) => {
        axios
          .post(`/lms/admin/course/deletelesson/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
